import grids from './grids';
import colors from './colors';
import spacings from './spacings';
import breakpoints from './breakpoints';
import typographyVariants from './typography';

const theme = {
  grids,
  colors,
  spacings,
  breakpoints,
  typographyVariants,
  borderRadius: '0.8rem',
  transition: 'all 0.2s ease 0s',
  fontFamily: '"Barlow", sans-serif',
  fontFamilySecondary: 'Barlow Condensed',
  fontFamilyTertiary: 'Helvetica',
};

export default theme;
