/**
 *
 * Arquivo responsavel por substituir as funciones do lodash
 *
 */

import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as isToday from 'dayjs/plugin/isToday';
import * as timezone from 'dayjs/plugin/timezone';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pt-br';

import VARIAVEIS_GLOBAIS from './globalVariables';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.locale('pt-br');

const YOUTUBE_API_KEY = '';

const DEFAULT_TIMEZONE =
  process.env.NEXT_PUBLIC_DEFAULT_TIMEZONE || 'America/Sao_Paulo';

// ALERTA: Está não é uma solução de substituição e
// pode não funcionar para alguns casos. Link lodash: https://lodash.com/docs/4.17.15#takeRight
export const takeRight = (arr, qty = 1) => [...arr].splice(-qty, qty);

// ALERTA: Essa não é uma solução de substituição e
// pode não funcionar em alguns casos extremos. Link lodash: https://lodash.com/docs/4.17.15#dropRight
export const dropRight = (arr, n = 1) => arr.slice(0, -n || arr.length);

// AlERTA: Essa não é uma solução de substituição e
// pode não funcionar. Teste seu código; Link lodash: https://lodash.com/docs/4.17.15#cloneDeep
export const cloneDeep = arr => JSON && JSON.parse(JSON.stringify(arr));

// ALERTA: Sempre teste seu código;
// Link lodash: https://lodash.com/docs/4.17.15#last
export const last = arr => (arr ? arr[arr.length - 1] : null);

// ALERTA: Sempre teste seu código;
// Link lodash: https://lodash.com/docs/4.17.15#size
export const size = item =>
  item?.constructor === Object ? Object.keys(item)?.length : item?.length;

// ALERTA: Sempre teste seu código;
// Link lodash: https://lodash.com/docs/4.17.15#take
export const take = (arr, qty = 1) => [...arr].splice(0, qty);

// ALERTA: Sempre teste seu código;
// Link lodash: https://lodash.com/docs/4.17.15#isObject
export const isObject = a => a instanceof Object;

// Alerta: Sempre teste seu código;
// Link lodash: https://lodash.com/docs/4.17.15#isNumber
export const isNumber = a => typeof a === 'number';

// Alerta: Sempre teste seu código;
// Link lodash: https://lodash.com/docs/4.17.15#isUndefined
export const isUndefined = val => val === undefined;

// Alerta: Sempre teste seu código;
// Link lodash: https://lodash.com/docs/4.17.15#isNull
export const isNull = val => val === null;

// Alerta: Sempre teste seu código;
// Link lodash: https://lodash.com/docs/4.17.15#isString
export const isString = a => typeof a === 'string';

// Alerta: Sempre teste seu código
// Link lodash: https://lodash.com/docs/4.17.15#drop
export const drop = (arr, n = 1) => arr?.slice?.(n) || arr;

// Alerta: Sempre teste seu código
// Link lodash: https://lodash.com/docs/4.17.15#trim
export const trim = (str, c = '\\s') =>
  str?.replace?.(new RegExp(`^([${c}]*)(.*?)([${c}]*)$`), '$2') || str;

// Alerta: Sempre teste seu código
// Link lodash: https://lodash.com/docs/4.17.15#head
export const head = arr => {
  let h;
  if (Array.isArray(arr)) {
    [h] = arr;
  }

  return h || [];
};

// Alerta: Sempre teste seu código
// Link lodash: https://lodash.com/docs/4.17.15#get
export const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
  const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    obj
  );
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
};

// Alerta: Sempre teste seu código
// Link lodash: https://lodash.com/docs/4.17.15#isBoolean
export const isBoolean = arg => arg === !!arg;

// Alerta: Sempre teste seu código
// Link lodash: https://lodash.com/docs/4.17.15#isEmpty
export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && size(Object.keys(value)) === 0) ||
  (typeof value === 'string' && size(value.trim()) === 0);

// Função mapValues customizada
export const mapValues = (obj, fn) => {
  if (!obj) {
    return {};
  }
  const result = {};
  Object.keys(obj).forEach(key => {
    result[key] = fn(obj[key]);
  });
  return result;
};

// Função groupBy customizada
export const groupBy = ({ collection, property, prefix = '' } = {}) =>
  collection.reduce((acc, item) => {
    const key = `${prefix}${item[property]}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

// Função mapObject customizada (anteriormente map)
export const mapObject = (obj, fn) => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).map(key => fn(obj[key], key));
};

const reKebabCase = /([0-9]+|([A-Z][a-z]+)|[a-z]+|([A-Z]+)(?![a-z]))/g;

// Função kebabCase customizada
export const kebabCase = str => {
  if (isString(str)) {
    // Normaliza a string removendo acentos e caracteres especiais
    const normalizedString = str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9 ]/g, '');

    // Aplica a regex para dividir a string conforme as regras originais
    return (normalizedString.match(reKebabCase) || [])
      .map(x => x.toLowerCase())
      .join('-');
  }

  return str;
};

// Alerta: Sempre teste seu código
// Link lodash: https://lodash.com/docs/4.17.15#remove
export const remove = ({ array, iteratee }) => {
  if (isEmpty(array)) return array;
  // in order to not mutate the original array until the very end
  // we want to cache the indexes to remove while preparing the result to return
  const toRemove = [];
  const result = array.filter((item, i) => iteratee(item) && toRemove.push(i));

  // just before returning, we can then remove the items, making sure we start
  // from the higher indexes: otherwise they would shift at each removal
  toRemove.reverse().forEach(i => array.splice(i, 1));
  return result;
};

// Faz o replace da string removendo as TAGS:
// <p>, </p> e \n
export const removeTagsOnString = ({ str }) => {
  const regex = /<p>(.*?)<\/p>/;
  const match = str?.match(regex);
  return match?.[1] || str;
};

// Adiciona dias na data enviada
export const adicionarDiasNaData = ({ data, dias }) => {
  data.setDate(data.getDate() + dias);
  return data.getDate();
};

// Adiciona meses na data enviada
export const adicionarMesesNaData = ({ data, meses }) => {
  data.setMonth(data.getMonth() + meses);

  return data.getMonth();
};

// Retornar somente o primeiro paragrafo dentro da TAG:
// P
export const retonarPrimeiroParagrafoDoConteudo = ({ str = '' }) => {
  const regex = /<p[^>]*>(.*?)<\/p>/;
  const stringMatched = str?.match(regex);

  return stringMatched?.[1] || str;
};

// Retorna o conteúdo da TAG informanda
export const extractContentOfTagTitle = ({ src }) => {
  const regex = /<title>(.*?)<\/title>/;

  if (isString(src)) {
    const match = src.match(regex);
    return match ? match[1] : null;
  }

  return null;
};

// Transforma o objeto em string, até o terceiro nível
export const objToString = ({ obj }) => {
  let str = '{';
  const keys = Object.keys(obj);

  for (let i = 0; i < keys.length; i += 1) {
    const val = obj[keys[i]];
    const isLast = keys.length - 1 === i;
    const isObjectVal = typeof val === 'object';
    const isArrayVal = Array.isArray(val);
    const valIsNumber = Number.isInteger(val);
    str = `${str}"${keys[i]}":`;

    if (isObjectVal && !isArrayVal) {
      str = `${str}{`;
      const subKeys = Object.keys(val);

      for (let b = 0; b < subKeys.length; b += 1) {
        const subVal = val[subKeys[b]];
        const isSubLast = subKeys.length - 1 === b;
        const subIsObject = typeof subVal === 'object';
        const subIsNumber = Number.isInteger(subVal);

        str = `${str}"${subKeys[b]}":`;

        if (subIsObject) {
          str = `${str}{`;
          const lastSubKeys = Object.keys(subVal);

          for (let c = 0; c < lastSubKeys.length; c += 1) {
            const isSubSubLast = lastSubKeys.length - 1 === c;
            const lastSubVal = subVal[lastSubKeys[c]];
            const lastSubIsNumber = Number.isInteger(lastSubVal);
            str = `${str}"${lastSubKeys[c]}":${
              lastSubIsNumber ? lastSubVal : `"${lastSubVal}"`
            }${isSubSubLast ? `}${isSubLast ? '}' : ','}` : ','}`;
          }
        } else {
          str = `${str}${subIsNumber ? subVal : `"${subVal}"`}${
            isSubLast ? `}${isLast ? '' : ','}` : ','
          }`;
        }
      }

      if (!subKeys.length) {
        str = `${str}}`;
      }

      if (isLast) {
        str = `${str}}`;
      }
    } else if (isArrayVal) {
      str = `${str}[${val}]${isLast ? '}' : ','}`;
    } else {
      str = `${str}${valIsNumber ? val : `"${val}"`}${isLast ? '}' : ','}`;
    }
  }

  str = `${str}`;
  // return obj;
  return str;
};

export const appendUrlParams = ({ url, additionalParams }) => {
  const urlObject = new URL(url);
  const currentParamsEntries = urlObject.searchParams.entries();
  const currentParams = Object.fromEntries(currentParamsEntries);

  const mergedEntries = { ...currentParams, ...additionalParams };

  const paramsEntries = Object.entries(mergedEntries);
  const params = new URLSearchParams(paramsEntries);

  urlObject.search = params.toString();

  return urlObject.toString();
};

export const getStateFromUrl = ({ url }) => {
  const urlObject = new URL(url);

  // state
  return Object.fromEntries(urlObject.searchParams.entries());
};

export const atualizandoMetaTagsESchema = ({
  seoProps,
  dados = { descricao: '' },
} = {}) => {
  const regex = /description" content=""/gm;
  const regex2 = /description" content="\*\*\*"/gm;
  const novoSeoProps = cloneDeep(seoProps);
  // let novoJsonLd = '';

  if (!size(seoProps.description)) {
    novoSeoProps.fullHead = novoSeoProps.fullHead.replace(
      regex,
      `description" content="${dados.descricao}"`
    );
  } else if (seoProps.description === '***') {
    novoSeoProps.fullHead = novoSeoProps.fullHead.replace(
      regex2,
      `description" content="${dados.descricao}"`
    );
    novoSeoProps.description = '';
  }

  return novoSeoProps;
};

// Retorna o valor do Cookie
export const obterCookiePeloNome = ({ nome = '' } = {}) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${nome}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();

  return '';
};

export const validarEmail = ({ email = '' } = {}) => {
  const e = String(email).toLowerCase();
  // Expressão regular para validar um email
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Testar se o email corresponde à expressão regular
  return re.test(e);
};

export const pad = ({ string }) => `0${string}`.slice(-2);

export const formatTime = ({ seconds }) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad({ string: date.getUTCSeconds() });

  return `${hh}:${pad({ string: mm })}:${ss}`;
};

// Retorna se o user-agent é mobile
export const verifyUserAgent = ({ userAgent = '' }) => {
  const isMobileView = Boolean(
    userAgent?.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );

  return isMobileView;
};

// Retorna se o user-agent é mobile
const BLOCKED_STRINGS = ['sem-categoria', '_next', 'favicon'];
export const verifyStringBlocks = ({ str }) => {
  if (!isString(str) || isEmpty(str)) return false;

  const block = BLOCKED_STRINGS.some(subs => str.includes(subs));

  return block || false;
};

// Obter o ID do vídeo pela URL de embed
export const getIdOnUrlEmbedYoutube = ({ url }) => {
  if (url && isString(url)) {
    const regex = /\/embed\/([^?]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  return url;
};

// Formatar padrão ISO 8601
export const formatDurationYoutube = ({ duration }) => {
  // Remove o prefixo 'PT'
  const time = duration.slice(2);

  const hoursMatch = time.match(/(\d+)H/);
  const minutesMatch = time.match(/(\d+)M/);
  const secondsMatch = time.match(/(\d+)S/);

  // Extrai horas, minutos e segundos, ou define como zero se não estiver presente
  const hours = hoursMatch ? hoursMatch[1].padStart(2, '0') : '00';
  const minutes = minutesMatch ? minutesMatch[1].padStart(2, '0') : '00';
  const seconds = secondsMatch ? secondsMatch[1].padStart(2, '0') : '00';

  return `${hours}:${minutes}:${seconds}`;
};

// Retorna o tempo total do vídeo no youtube utilizando a API
export const getVideoDuration = async ({ videoId }) => {
  const url = new URL('https://www.googleapis.com/youtube/v3/videos');

  if (!videoId) return null;

  const params = {
    id: videoId,
    key: YOUTUBE_API_KEY,
    part: 'contentDetails',
  };

  url.search = new URLSearchParams(params).toString();

  try {
    const response = await fetch(url);
    if (!response.ok) {
      // throw new Error(`HTTP error! Status: ${response.status}`);
      console.error(`HTTP error! Status: ${response.status}`);

      return '';
    }
    const data = await response.json();
    let duration = 'PT0H0M0S';
    if (head(data?.items)) {
      duration = head(data?.items)?.contentDetails?.duration;
    }

    // Retorna a duração no formatada
    return formatDurationYoutube({ duration });
  } catch (error) {
    console.error('Error fetching video duration:', error.message);

    return {};
  }
};

export const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

/**
 * Retrieves the ID from a Sportradar URL using the logo's URL.
 * Note: This function is specifically designed to work only with Sportradar URLs.
 *
 * getIdOnUrlLogo
 * @param {string} url
 * @returns string
 */

export const getIdOnUrlLogo = ({ url = '' } = {}) => {
  const match = url?.match(/\/(\d+)(?:\.\w+)?$/);

  return match ? match[1] : 0;
};

/**
 * Pads the beginning of a string with the specified character until the desired length is reached.
 *
 * @param {string} str - The string to pad.
 * @param {number} max - The total length of the string after padding.
 * @param {string} prefix - The character used to pad the string if it is shorter than the maximum length.
 */
export const padStart = ({ str, max = 2, prefix = '0' }) => {
  if (isNull(str) || isUndefined(str)) return str;

  return String(str).padStart(max, prefix);
};

/**
 * Retorna somente a quantidade de caracteres de uma string;
 *
 * getAbreviation
 * @param {string} str
 * @param {number} total
 * @returns string
 */

export const getAbreviation = ({ str = '', total = 3 } = {}) => {
  let newStr = str;
  if (!isString(str)) {
    newStr = '';
  }
  let pattern = '^';
  for (let i = 0; i < total; i += 1) {
    pattern += '(.)';
  }
  pattern = `${pattern}?`;

  const reg = new RegExp(pattern);

  return head(newStr.match(reg));
};

/**
 * Retorna a estrutura do texto para a substituição do jogador;
 *
 * getPlayerNameSubstitution
 * @param {string} str
 * @returns string
 */

export const getPlayerNameSubstitution = ({ str = '' }) => {
  if (!isString(str)) return str;

  const regex = /Entra\s+([\w\s]+)\.\s+Sai\s+([\w\s]+)/;
  const match = str.match(regex);

  if (match) {
    return { in: match[1].trim(), out: match[2].trim() };
  }

  return { in: null, out: null };
};

/**
 * Retrieves the ID from a Sportradar URL using the logo's URL.
 * Note: This function is specifically designed to work only with Sportradar URLs.
 *
 * setUrlLogoById
 * @param {string} url
 * @returns string
 */

export const setUrlLogoById = ({ id = '' } = {}) => {
  if (!isEmpty(id)) {
    return `https://img.sportradar.com/ls/crest/medium/${id}.png`;
  }

  return '/images/logos/shield-team-placeholder.svg';
};

/**
 * Extrai o texto de acordo com a Regex:
 * /para\s+([\w\s]+?)\s+do/
 *
 * getPlayerName
 * @param {string} str
 * @returns string
 */

export const getPlayerName = ({ str = '' }) => {
  if (!isString(str)) return str;

  const regex = /para\s+([\w\s]+?)\s+do/;
  const match = str.match(regex);
  return match ? match[1].trim() : null;
};

/**
 * Funções para utilizar o dayJS
 *
 */

export const getDiffOnDates = ({ dateA, dateB, type = 'millisecond' }) =>
  dayjs(dateA).diff(dateB ? dayjs(dateB) : dayjs(), type);

export const isBeforeDate = ({ dateA, dateB, type = 'millisecond' }) =>
  dayjs(dateA).isBefore(dateB ? dayjs(dateB) : dayjs(), type);

export const isAfterDate = ({ dateA, dateB, type = 'millisecond' }) =>
  dayjs(dateA).isAfter(dateB ? dayjs(dateB) : dayjs(), type);

export const formatDate = ({
  date,
  format = 'DD/MM/YYYY',
  configs = { string: false, format: 'DD/MM/YYYY' },
}) => {
  if (configs.string) {
    return dayjs(date, configs?.format).format(format);
  }
  return dayjs(date).format(format);
};

export const isTodayDate = ({ date }) => dayjs(date).isToday();

export const getDateFromNow = ({ date } = {}) => dayjs(date).fromNow();

/**
 * Returns the current date in the configured timezone
 * @returns {Object} dayjs object in the configured timezone
 */
export const getTodayDate = () => dayjs().tz(DEFAULT_TIMEZONE);

export const setAddDate = ({ date, range, type = 'day' }) =>
  date.add(range, type);

const createRegexFromName = (teamName = '') => {
  // Separate by space or hyphen
  const teamParts = teamName.split(/[\s-]+/);
  // Join the parts to allow variations in the name sequence
  return teamParts.join('|');
};

// Function to remove accents and special characters
const removeAccents = (str = '') =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9\s]/g, ' ');

const processText = (str = '') =>
  removeAccents(str)
    .replace(/\s+/g, ' ')
    .split(' ')
    .filter(word => word.length >= 3)
    .join(' ')
    .trim();

// Create regular expressions for variations based on popular names
const teamRegex = team =>
  new RegExp(createRegexFromName(processText(team)), 'g');

export const categorizeFunFacts = ({ facts, teamA = '', teamB = '' }) => {
  const teamALower = teamA?.toLowerCase() ?? '';
  const teamBLower = teamB?.toLowerCase() ?? '';

  const categorizedFacts = {
    teamA: [],
    teamB: [],
    both: [],
    others: [],
  };

  facts.forEach(fact => {
    const factLower = removeAccents(fact.toLowerCase());

    const teamARegex = teamRegex(teamALower);
    const teamBRegex = teamRegex(teamBLower);

    const mentionsTeamA = teamARegex.test(factLower);
    const mentionsTeamB = teamBRegex.test(factLower);

    if (mentionsTeamA && mentionsTeamB) {
      categorizedFacts.both.push(fact);
    } else if (mentionsTeamA) {
      categorizedFacts.teamA.push(fact);
    } else if (mentionsTeamB) {
      categorizedFacts.teamB.push(fact);
    } else {
      categorizedFacts.others.push(fact);
    }
  });

  return categorizedFacts;
};

// Helper function for URI construction:
export const createFullUri = path => {
  if (!path || typeof path !== 'string') return '';
  if (!VARIAVEIS_GLOBAIS.HOST_GAMEARENA) {
    console.error('HOST_GAMEARENA is not defined');
    return '';
  }
  // Remove any attempts at path traversal
  const normalizedPath = path.replace(/\.{2,}/g, '').trim();
  const sanitizedPath = encodeURI(normalizedPath);
  return `${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}${sanitizedPath}`;
};
