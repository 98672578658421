import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import DOMPurify from 'isomorphic-dompurify';

import propToStyle from 'theme/utils/propToStyle';
import breakpointsMedia from 'theme/utils/breakpointsMedia';
import { mapValues, isObject, get } from 'theme/utils/functions';

import theme from '../../../theme';

export const TextStyleVariants = ({ $variant } = {}) => {
  if (isObject($variant)) {
    const styles = breakpointsMedia({
      ...mapValues(
        theme.typographyVariants[$variant.typography],
        typography => ({
          ...typography,
        })
      ),
    });

    return css`
      ${styles}
    `;
  }

  return css`
    font-size: ${theme.typographyVariants?.[$variant]?.fontSize};
    font-style: ${theme.typographyVariants?.[$variant]?.fontStyle};
    font-weight: ${theme.typographyVariants?.[$variant]?.fontWeight};
    line-height: ${theme.typographyVariants?.[$variant]?.lineHeight};
    font-family: ${theme.typographyVariants?.[$variant]?.fontFamily ||
    'inherit'};
    letter-spacing: ${theme.typographyVariants?.[$variant]?.letterSpacing ||
    'inherit'};
    text-transform: ${theme.typographyVariants?.[$variant]?.textTransform ||
    'inherit'};
  `;
};

export const TextBase = styled.span`
  ${({ $variant }) => TextStyleVariants({ $variant })}

  ${propToStyle('$color')}
  ${propToStyle('$maxWidth')}
  ${propToStyle('$fontStyle')}
  ${propToStyle('$marginTop')}
  ${propToStyle('$wordBreak')}
  ${propToStyle('$marginLeft')}
  ${propToStyle('$marginRight')}
  ${propToStyle('$marginBottom')}
  ${propToStyle('$letterSpacing')}
  ${propToStyle({ prop: '$cssMargin', css: 'margin' })}
  ${propToStyle({ prop: '$cssPadding', css: 'padding' })}
  ${propToStyle({ prop: '$cssDisplay', css: 'display' })}
  ${propToStyle({ prop: '$cssFontSize', css: 'fontSize' })}
  ${propToStyle({ prop: '$cssTextAlign', css: 'textAlign' })}
  ${propToStyle({ prop: '$cssLineHeight', css: 'lineHeight' })}
  ${propToStyle({ prop: '$cssFontWeight', css: 'fontWeight' })}
  ${propToStyle({ prop: '$cssPaddingTop', css: 'paddingTop' })}
  ${propToStyle({ prop: '$cssTextTransform', css: 'textTransform' })}
  ${propToStyle({ prop: '$cssPaddingBottom', css: 'paddingBottom' })}

  color: ${({ $csscolor }) =>
    get(theme, `colors.base.${$csscolor}`) ||
    get(theme, `colors.${$csscolor}`) ||
    get(theme, 'colors.base.900')};
`;

const Text = ({
  children,
  tag = 'span',
  $gridPadding = false,
  $variant = { typography: 'bodyText' },
  ...props
}) => {
  const Purify = DOMPurify;

  if (props.$setInnerHTML && Purify.sanitize) {
    // const regexRemoveTag = /(<([^>]+)>)/gi;
    return (
      <TextBase
        as={tag}
        $variant={$variant}
        $gridPadding={$gridPadding}
        dangerouslySetInnerHTML={{
          __html: Purify.sanitize(props.$setInnerHTML.html),
          // .replace('[...]', '')
          // .replace(regexRemoveTag, ''),
        }}
        {...props}
      />
    );
  }

  return (
    <TextBase
      as={tag}
      $variant={$variant}
      {...props}
    >
      {children}
    </TextBase>
  );
};

Text.propTypes = {
  tag: PropTypes.string,
  $gridPadding: PropTypes.bool,
  $variant: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]),
  $setInnerHTML: PropTypes.shape({
    html: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default React.memo(Text);
