const breakpoints = {
  xs: 0, // extra small
  sm: 480, // small
  md: 768, // medium
  lg: 992, // large
  xl: 1280, // extra large
  xxl: 1400, // Extra extra large
  widescreen: 1152 // to wide screen
};

export default breakpoints;
