import { css } from 'styled-components';

import { isObject, mapValues, kebabCase } from 'theme/utils/functions';
import breakpointsMedia from './breakpointsMedia';

const propToStyle = propName => props => {
  let propValue = '';
  const isObjectProps = isObject(propName);
  const pName = isObjectProps ? propName.css : propName;

  if (isObjectProps) {
    propValue = props[propName.prop];
  } else {
    propValue = props[propName];
  }

  if (isObject(propValue)) {
    return css`
      ${breakpointsMedia({
        ...mapValues(propValue, cssElement => ({
          [kebabCase(pName)]: cssElement,
        })),
      })}
    `;
  }

  return {
    [kebabCase(pName)]: props[isObjectProps ? propName.prop : pName],
  };
};

export default propToStyle;
