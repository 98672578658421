const barlowMedium = '"Barlow", sans-serif';

const typographyData = {
  TabButtonText: {
    xs: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '1.85rem',
      fontFamily: barlowMedium,
    },
  },
};

export default typographyData;
