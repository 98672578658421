import { css } from 'styled-components';
import { mapObject } from 'theme/utils/functions';
import breakpoints from '../breakpoints';

const breakpointsMedia = cssByBreakpoints =>
  mapObject(
    cssByBreakpoints,
    (cssElement, breakpointName) => css`
      @media screen and (min-width: ${breakpoints[breakpointName]}px) {
        ${cssElement}
      }
    `
  );

export default breakpointsMedia;
