/*
 * Variaveis utilizadas no projeto.
 */

import addDays from './addDays';

const TIMES = {
  // Time constants
  FIVE_SECONDS: 5,
  ONE_DAY_SECONDS: 86400,
  ONE_MINUTE_SECONDS: 60,
  TEN_DAYS_SECONDS: 864000,
  SIX_MINUTES_SECONDS: 360,
  FIVE_MINUTES_SECONDS: 300,
  THREE_MINUTES_SECONDS: 180,
  ONE_MONTH_SECONDS: 2629800,
};

const VARIAVEIS_GLOBAIS = {
  // REVALIDATE TIME IN SECONDS,
  TIMES,
  // URL Global do Projeto
  HOST_GAMEARENA:
    process.env.isDev && !process.env.simulateProd
      ? 'https://gameareana.com'
      : process.env.webHosting || 'https://gamearena.gg',
  DEFAULT_LOCAL_STORAGE: {
    menus: {
      purge: addDays({ date: new Date(), days: 2 }),
    },
  },
  PAGES_TYPES: {
    Page: 'Page',
    Tags: 'Tags',
    Article: 'Article',
    Authors: 'Authors',
    Category: 'Category',
    ArticleNews: 'ArticleNews',
    ArticleVideo: 'ArticleVideo',
  },
  // STATUS CODE
  FORBIDDEN_ACCESS: 403,
  // POST TYPES
  POST_TYPES: {
    VIDEO: 'video',
  },
  // CACHE
  CACHE: {
    /** Cache duration for home page: 5 minutes for fresh content */
    HOME: TIMES.FIVE_MINUTES_SECONDS,
    /** Cache duration for category pages: 6 minutes balancing freshness and performance */
    CATEGORY: TIMES.SIX_MINUTES_SECONDS,
    /** Cache duration for latest news: 3 minutes for timely updates */
    SEARCH_RESULTS: TIMES.THREE_MINUTES_SECONDS,
    /** Cache duration for articles: 1 month for static content */
    ARTICLES: TIMES.ONE_MONTH_SECONDS,
    /** Cache duration for live matches: 1 minute for real-time updates */
    MATCH_PAGE_LIVE: TIMES.ONE_MINUTE_SECONDS,
    /** Cache duration for ended matches: 1 month as content is static */
    MATCH_PAGE_ENDED: TIMES.ONE_MONTH_SECONDS,
  },
};

export default VARIAVEIS_GLOBAIS;
