const spacings = {
  xs: '0.4rem',
  sm: '0.8rem',
  smd: '1.2rem',
  md: '1.6rem',
  lg: '2rem',
  xl: '2.4rem',
  xxl: '3.2rem',
  xxxl: '4rem',
};

export default spacings;
